import { createBrowserRouter } from "react-router-dom";
import { Suspense, lazy } from "react";

const Home = lazy(() => import("@pages/home"));
const Qa = lazy(() => import("@pages/qa"));
const Rank = lazy(() => import("@pages/rank"));
const ThematicLearning = lazy(() => import("@pages/thematicLearning"));
const ThematicList = lazy(() => import("@pages/thematicList"));
const Market = lazy(() => import("@pages/market"));
const Rule = lazy(() => import("@pages/rule"));
const Manager = lazy(() => import("@pages/manager"));
const MyExchange = lazy(() => import("@pages/myExchange"));

const routers = createBrowserRouter([
  {
    path: "*",
    element: (
      <Suspense fallback="loading...">
        <Home />
      </Suspense>
    ),
  },
  {
    path: "/home",
    element: (
      <Suspense fallback="loading...">
        <Home />
      </Suspense>
    ),
  },
  {
    path: "/qa",
    element: (
      <Suspense fallback="loading...">
        <Qa />
      </Suspense>
    ),
  },
  {
    path: "/rank",
    element: (
      <Suspense fallback="loading...">
        <Rank />
      </Suspense>
    ),
  },
  {
    path: "/thematicList",
    element: (
      <Suspense fallback="loading...">
        <ThematicList />
      </Suspense>
    ),
  },
  {
    path: "/thematicLearning",
    element: (
      <Suspense fallback="loading...">
        <ThematicLearning />
      </Suspense>
    ),
  },
  {
    path: "/market",
    element: (
      <Suspense fallback="loading...">
        <Market />
      </Suspense>
    ),
  },
  {
    path: "/rule",
    element: (
      <Suspense fallback="loading...">
        <Rule />
      </Suspense>
    ),
  },
  {
    path: "/manager",
    element: (
      <Suspense fallback="loading...">
        <Manager />
      </Suspense>
    ),
  },
  {
    path: "/myExchange",
    element: (
      <Suspense fallback="loading...">
        <MyExchange />
      </Suspense>
    ),
  },
]);

export default routers;
